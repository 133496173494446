import { useEffect, useMemo, useRef } from 'react';

import {
  PortalComponent,
  validateFunctionalComponent
} from '@/common/components/Portal/DomPortal.types';
import { usePortalComponentActions } from '@/common/components/Portal/DomPortalProvider';
import { useConstantCallback } from '@komo-tech/core/hooks/useConstant';
import { useDeepCompareMemo } from '@komo-tech/core/hooks/useDeepCompareEffects';
import { useStateIfMounted } from '@komo-tech/core/hooks/useStateIfMounted';

const generateKey = (() => {
  let count = 0;
  return () => `${++count}`;
})();

export const useModal = (
  component: PortalComponent,
  inputs: any[] = [],
  deepCompareDeps: boolean = false
) => {
  const modalActions = usePortalComponentActions((x) => x.modals);
  const key = useMemo(generateKey, []);

  const memoFunc = useRef(deepCompareDeps ? useDeepCompareMemo : useMemo);

  validateFunctionalComponent(component);
  const memodComponent = memoFunc.current(() => component, inputs);
  const [isShown, setShown] = useStateIfMounted<boolean>(false);
  const handleShow = useConstantCallback(() => setShown(true));
  const handleHide = useConstantCallback(() => setShown(false));

  useEffect(() => {
    if (isShown) {
      modalActions.show(key, memodComponent);
    } else {
      modalActions.hide(key);
    }

    // Hide modal when parent component unmounts
    return () => modalActions.hide(key);
  }, [memodComponent, isShown]);

  return [handleShow, handleHide, isShown] as const;
};
